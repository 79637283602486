import { axiosInstance } from "../axios";

const postCreateRequestOpportunityApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post(
      "/request-opportunities/create",
      body
    );
    successCallback(response);
    return response;
  } catch (error) {
    errorCallback(error);
    return error;
  }
};

const postCreateQuickRequestOpportunityApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/createUserAndBroadcast", body);
    successCallback(response);
    return response;
  } catch (error) {
    errorCallback(error);
    return error;
  }
};

const getOpenFormsApi = async (category_id: number) => {
  try {
    let response = await axiosInstance.get(
      `/open/forms/get-all/${category_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCategoriesApi = async (franchise_id: number) => {
  try {
    let response = await axiosInstance.get(
      `/categories?franchise_id=${franchise_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getGoalsApi = async () => {
  try {
    let response = await axiosInstance.get(`/goal`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getInjuriesApi = async () => {
  try {
    let response = await axiosInstance.get(`/injury`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBroadcastStatesApi = async (country_id: number, all: boolean) => {
  try {
    let response = await axiosInstance.get(
      `/geo?all=${all}&country=${country_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBroadcastCitiesApi = async (
  country_id: number,
  state_id: number,
  all: boolean
) => {
  try {
    let response = await axiosInstance.get(
      `/geo?all=${all}&country=${country_id}&state=${state_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const postCreateBroadcastRequestApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/request", body);
    successCallback(response);
    return response;
  } catch (error) {
    errorCallback(error);
    return error;
  }
};

export {
  postCreateRequestOpportunityApi,
  postCreateQuickRequestOpportunityApi,
  getOpenFormsApi,
  getCategoriesApi,
  getGoalsApi,
  getInjuriesApi,
  getBroadcastStatesApi,
  getBroadcastCitiesApi,
  postCreateBroadcastRequestApi,
};
