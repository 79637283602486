import { Col, Container, Row, ListGroup } from "react-bootstrap";
import "./style.css";

const HowToDelete = () => {
  return (
    <>
      <Container className="min-height-vh-75">
        <Row className="mt-3 justify-content-center">
          <Col className="p-0" lg="5" md="8" sm="10" xs="12">
            <h3 className="p-0 m-0 text-uppercase fw-bolder">How to Delete</h3>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="5" md="8" sm="10" xs="12">
            <Row className="mt-5">
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">Login to your account</ListGroup.Item>
                <ListGroup.Item as="li">
                  Click on burger icon at top left corner of your screen.
                </ListGroup.Item>
                <ListGroup.Item as="li">Click on settings</ListGroup.Item>
                <ListGroup.Item as="li">Click on Delete Account</ListGroup.Item>
              </ListGroup>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { HowToDelete };
