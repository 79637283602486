import React, { useEffect, useState } from "react";
import "./style.css";
import PropTypes from "prop-types";
import { PrimaryModal } from "../PrimaryModal";
import {
  AppDispatch,
  getCategoriesAction,
  getGoalsAction,
  setBroadcastRequestIsVisibleAction,
  getInjuriesAction,
  getBroadcastCitiesAction,
  getBroadcastStatesAction,
  postCreateBroadcastRequestAction,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Accordion,
  Button,
  Col,
  Form,
  Image,
  ProgressBar,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  BOTTOM_BOX,
  IC_BOX,
  IC_SELECT,
  IC_TICK,
  MIDDLE_BOX,
  TOP_BOX,
} from "../../assets/images";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import moment from "moment";
import { DaysPicker } from "../DaysCalendar";

interface BroadcastRequestModalProps {
  isOpen: boolean;
  onHide: any;
  title: any;
  steps: any;
}

const BroadcastRequestModal = (props: BroadcastRequestModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { steps: stepsArray } = props;
  const {
    categories,
    goals,
    injuries = [],
    states = [],
    cities = [],
  } = useSelector((state: any) => state.broadcastRequestReducer);

  const { profile, selectedFranchise } = useSelector(
    (state: any) => state.user
  );
  const [step, setStep] = useState(0);
  const [form, setForm] = useState<any>({
    hours: null,
    is_flexible: true,
    pref_start_time_string: null,
    categories: [],
    goals: [],
    injuries: [],
    place: "",
    periodicity: "",
    prefer_sex: "",
    selected_state: "",
    selected_city: "",
    other_category: null,
    other_goal: null,
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = async (arr: any) => {
    await dispatch(
      postCreateBroadcastRequestAction(
        { ...form, user_id: profile.id },
        () => {
          setLoading(false);
          toast("Request added successfully", {
            autoClose: 3000,
          });
          setStep(0);
          setForm({
            hours: null,
            is_flexible: true,
            pref_start_time_string: null,
            categories: [],
            goals: [],
            injuries: [],
            place: "",
            periodicity: "",
            prefer_sex: "",
            selected_state: "",
            selected_city: "",
            other_category: null,
            other_goal: null,
          });
          setStepDetails(arr);
          dispatch(setBroadcastRequestIsVisibleAction(false));
        },
        () => {
          setLoading(false);

          toast.error("Something went wrong!", {
            autoClose: 3000,
          });
        }
      )
    );
  };
  const handleNext = async () => {
    if (step === 0 || step === 1 || step === 2 || step === 4 || step === 6) {
      if (stepDetails[step].data.filter((obj: any) => obj.selected).length) {
        setStep(step + 1);
      } else {
        toast.error("Please answer required question(s)", {
          autoClose: 3000,
        });
      }
    } else if (step === 3) {
      if (
        form.formatted_address &&
        form.selected_state &&
        form.selected_city &&
        form.zipCode &&
        form.place
      )
        setStep(step + 1);
      else
        toast.error("Please answer required question(s)", {
          autoClose: 3000,
        });
    } else if (step === 5) {
      if (form.hours && form.pref_start_time_string) setStep(step + 1);
      else
        toast.error("Please answer required question(s)", {
          autoClose: 3000,
        });
    } else if (step === stepDetails?.length - 1) {
      onSubmit([...stepsArray]);
    } else {
      setStep(step + 1);
    }
  };

  const renderFooter = () => {
    return (
      <Row className="justify-content-center w-100">
        <Col xs="12" sm="8" lg="5">
          <Row className="justify-content-end">
            {step !== 0 ? (
              <Col xs={6}>
                <Button
                  variant="secondary"
                  className="font-family-poppins mt-2 w-100"
                  onClick={() => {
                    if (step >= 1) setStep(step - 1);
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    "Back"
                  )}
                </Button>
              </Col>
            ) : null}
            <Col xs={6}>
              <Button
                variant="primary"
                className="background-primary w-100 border-color-primary font-family-poppins text-light mt-2"
                onClick={handleNext}
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : step === stepDetails?.length - 1 && step !== 0 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderAddressStep = () => (
    <>
      <p className="w-100 mt-2 mb-0 text-center">ENTER YOUR ADDRESS</p>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyC-uV3wsG3ALfeYMCU_i_RvlNs8pgHpj5E"
        selectProps={{
          location: "",
          onChange: async (place: any) => {
            let result: any = await geocodeByPlaceId(place?.value?.place_id);
            if (result?.length) {
              let _result: any = result[0];
              const tempForm: any = {};
              tempForm.formatted_address = _result?.formatted_address;
              tempForm.coordinate = `${_result?.geometry?.location.lat()},${_result?.geometry?.location.lng()}`;
              setForm({ ...form, ...tempForm });
            }
          },
        }}
      />
      <p className="w-100 mt-2 mb-0 text-center">CONFIRM YOUR LOCATION</p>
      <p className="w-100 mt-2 mb-0">State</p>
      <Accordion className="mt-2">
        <Accordion.Item eventKey="10">
          <Accordion.Header className="item">
            <span className="ms-1">
              {form.selected_state
                ? form.selected_state
                : `Select all that apply`}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            {stepDetails[step]?.states?.map((obj: any, index: number) => (
              <div
                onClick={() => handleOptionSelect(step, obj, index)}
                className={`optionContainer ${
                  obj.selected ? "optionSelected" : ""
                }`}
              >
                {String(obj.name).toLowerCase() !== "other" && obj.icon ? (
                  <></>
                ) : null}
                {obj.name && <span>{obj.name}</span>}
                {
                  <Image
                    className={
                      obj.selected ? "option-tick-selected" : "option-tick"
                    }
                    src={obj.selected ? IC_SELECT : IC_BOX}
                  />
                }
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p className="w-100 mt-2 mb-0">City</p>
      <Accordion className="mt-2">
        <Accordion.Item eventKey="10">
          <Accordion.Header className="item">
            <span className="ms-1">
              {form.selected_city
                ? form.selected_city
                : `Select all that apply`}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            {stepDetails[step]?.cities?.map((obj: any, index: number) => (
              <div
                onClick={() => handleOptionSelect(step, obj, index)}
                className={`optionContainer ${
                  obj.selected ? "optionSelected" : ""
                }`}
              >
                {String(obj.name).toLowerCase() !== "other" && obj.icon ? (
                  <></>
                ) : null}
                {obj.name && <span>{obj.name}</span>}
                {
                  <Image
                    className={
                      obj.selected ? "option-tick-selected" : "option-tick"
                    }
                    src={obj.selected ? IC_SELECT : IC_BOX}
                  />
                }
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p className="w-100 mt-3 mb-3 text-center">ENTER YOUR ZIP CODE</p>
      <Form.Control
        value={form.zipCode || ""}
        onChange={(e) => {
          setForm({ ...form, zipCode: e.target.value });
        }}
        type="text"
        placeholder="Type here."
      />
      <p className="w-100 mt-3 mb-3 text-center">{stepDetails[step].title}</p>
      <Accordion className="mt-2">
        <Accordion.Item eventKey="10">
          <Accordion.Header className="item">
            <span className="ms-1">
              {form.place ? form.place : `Select all that apply`}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            {stepDetails[step].data.map((obj: any, index: number) => (
              <div
                onClick={() => handleOptionSelect(step, obj, index)}
                className={`optionContainer ${
                  obj.selected ? "optionSelected" : ""
                }`}
              >
                {String(obj.name).toLowerCase() !== "other" && obj.icon ? (
                  <></>
                ) : null}
                {obj.name && <span>{obj.name}</span>}
                {
                  <Image
                    className={
                      obj.selected ? "option-tick-selected" : "option-tick"
                    }
                    src={obj.selected ? IC_SELECT : IC_BOX}
                  />
                }
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );

  const handleOptionSelect = (step: any, obj: any, index: number) => {
    let _step_details = JSON.parse(JSON.stringify(stepDetails));
    if (_step_details[step].data[index].selected) {
      _step_details[step].data[index].selected = false;
      if (step === 0)
        setForm({
          ...form,
          categories: [
            ...form.categories.filter(
              (id: any) => id !== _step_details[step].data[index].id
            ),
          ],
        });
      if (step === 1)
        setForm({
          ...form,
          goals: [
            ...form.goals.filter(
              (id: any) => id !== _step_details[step].data[index].id
            ),
          ],
        });
      if (step === 2)
        setForm({
          ...form,
          injuries: [
            ...form.injuries.filter(
              (id: any) => id !== _step_details[step].data[index].id
            ),
          ],
        });
      if (step === 3 && !obj.country_id && !obj.state_id) {
        setForm({ ...form, place: "" });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
      }
      if (step === 4) {
        setForm({ ...form, periodicity: "" });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
      }
      if (step === 6) {
        setForm({ ...form, prefer_sex: "" });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
      }
    } else {
      _step_details[step].data[index].selected = true;
      if (step === 0)
        setForm({
          ...form,
          categories: [...form.categories, _step_details[step].data[index].id],
        });
      if (step === 1)
        setForm({
          ...form,
          goals: [...form.goals, _step_details[step].data[index].id],
        });
      if (step === 2)
        setForm({
          ...form,
          injuries: [...form.injuries, _step_details[step].data[index].id],
        });
      if (step === 3 && !obj.country_id && !obj.state_id) {
        setForm({ ...form, place: _step_details[step].data[index].name });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
        _step_details[step].data[index].selected = true;
      }
      if (step === 4) {
        setForm({ ...form, periodicity: _step_details[step].data[index].name });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
        _step_details[step].data[index].selected = true;
      }
      if (step === 6) {
        setForm({
          ...form,
          prefer_sex:
            _step_details[step].data[index].name === "Male" ||
            _step_details[step].data[index].name === "Female"
              ? _step_details[step].data[index].name.toLowerCase()
              : "none",
        });
        let tempArr = _step_details[step].data;
        tempArr = tempArr.map((obj: any) => {
          return {
            ...obj,
            selected: false,
          };
        });
        _step_details[step].data = tempArr;
        _step_details[step].data[index].selected = true;
      }
    }
    if (step === 3 && obj.country_id) {
      _step_details[step].states = _step_details[step].states.map(
        (obj: any, _index: number) => {
          if (index === _index) {
            return {
              ...obj,
              selected: true,
            };
          } else {
            return {
              ...obj,
              selected: false,
            };
          }
        }
      );
      setForm({ ...form, selected_state: obj.name, selected_state_id: obj.id });
    }

    if (step === 3 && obj.state_id) {
      _step_details[step].cities = _step_details[step].cities.map(
        (obj: any, _index: number) => {
          if (index === _index) {
            return {
              ...obj,
              selected: true,
            };
          } else {
            return {
              ...obj,
              selected: false,
            };
          }
        }
      );
      setForm({ ...form, selected_city: obj.name, selected_city_id: obj.id });
    }
    setStepDetails(_step_details);
  };

  const [stepDetails, setStepDetails] = useState<any>([...stepsArray]);

  useEffect(() => {
    let [categoryDetails, ...restDetails] = stepDetails;
    if (!stepDetails[0].data.length && categories.length) {
      setStepDetails([
        {
          ...categoryDetails,
          data: [
            ...categories,
            {
              id: "other",
              name: "Other",
            },
          ],
        },
        ...restDetails,
      ]);
    }
    // eslint-disable-next-line
  }, [categories, stepDetails]);

  useEffect(() => {
    let [first, goalDetails, ...restDetails] = stepDetails;
    if (!stepDetails[1].data.length && goals.length) {
      setStepDetails([
        first,
        {
          ...goalDetails,
          data: [...goals],
        },
        ...restDetails,
      ]);
    }
    // eslint-disable-next-line
  }, [goals, stepDetails]);

  useEffect(() => {
    let [first, second, injuryDetails, ...restDetails] = stepDetails;
    if (!stepDetails[2].data.length && injuries.length) {
      setStepDetails([
        first,
        second,
        {
          ...injuryDetails,
          data: [...injuries],
        },
        ...restDetails,
      ]);
    }
    // eslint-disable-next-line
  }, [injuries, stepDetails]);

  useEffect(() => {
    let [first, second, third, location, ...restDetails] = stepDetails;
    if (
      !stepDetails[3]?.states &&
      !stepDetails[3]?.states?.length &&
      states.length
    ) {
      setStepDetails([
        first,
        second,
        third,
        {
          ...location,
          states: [...states],
        },
        ...restDetails,
      ]);
      let selectedState = states.filter(
        (obj: any) => obj.id === profile.state_id
      );
      setForm({
        ...form,
        selected_state: selectedState.name,
        selected_state_id: selectedState.id,
      });
    }
    // eslint-disable-next-line
  }, [states, stepDetails]);

  useEffect(() => {
    let [first, second, third, location, ...restDetails] = stepDetails;
    if (
      !stepDetails[3]?.cities &&
      !stepDetails[3]?.cities?.length &&
      cities.length
    ) {
      setStepDetails([
        first,
        second,
        third,
        {
          ...location,
          cities: [...cities],
        },
        ...restDetails,
      ]);
      let selectedCity = cities.filter(
        (obj: any) => obj.id === profile.city_id
      );
      setForm({
        ...form,
        selected_city: selectedCity.name,
        selected_city_id: selectedCity.id,
      });
    }
    // eslint-disable-next-line
  }, [cities, stepDetails]);

  useEffect(() => {
    if (selectedFranchise && selectedFranchise.id && profile && profile.id) {
      if (!stepDetails[0].data.length)
        dispatch(getCategoriesAction(selectedFranchise.id));
      if (!stepDetails[1].data.length) dispatch(getGoalsAction());
      if (!stepDetails[2].data.length) dispatch(getInjuriesAction());
    }
    // eslint-disable-next-line
  }, [selectedFranchise]);

  useEffect(() => {
    profile.country_id &&
      dispatch(getBroadcastStatesAction(profile.country_id, true));
    // eslint-disable-next-line
  }, [profile?.country_id]);

  useEffect(() => {
    profile.country_id &&
      profile.state_id &&
      dispatch(
        getBroadcastCitiesAction(profile.country_id, profile.state_id, true)
      );
    // eslint-disable-next-line
  }, [profile?.state_id]);

  return (
    <>
      {stepDetails && (
        <PrimaryModal
          isOpen={props.isOpen}
          onHide={() => {
            setLoading(false);
            setStep(0);
            setForm({
              hours: null,
              is_flexible: true,
              pref_start_time_string: null,
              categories: [],
              goals: [],
              injuries: [],
              place: "",
              periodicity: "",
              prefer_sex: "",
              selected_state: "",
              selected_city: "",
              other_category: null,
              other_goal: null,
            });
            setStepDetails(stepsArray);
            props.onHide();
          }}
          size={"xl"}
          centered={true}
          fullscreen={true}
          title={props.title}
          footer={renderFooter()}
        >
          <Row className="contentWrapper justify-content-center">
            <Col xs="12" sm="8" lg="5">
              <div style={{ flexDirection: "row" }}>
                <ProgressBar variant="info" now={stepDetails[step].progress} />
                <p
                  className={
                    step === 8
                      ? "w-100 mt-1 mb-3 text-center desctiption-text"
                      : "w-100 mt-2 mb-0 text-center name-text"
                  }
                >
                  {stepDetails[step].title}
                </p>
                <p
                  className={
                    step === 8
                      ? "w-100 mt-3 text-center name-text"
                      : "w-100 mt-1 text-center desctiption-text"
                  }
                >
                  {stepDetails[step].sub_title}
                </p>
              </div>
              <div style={{ width: "100%", marginTop: 10 }}>
                {step === 3 && renderAddressStep()}
                {step === 5 && (
                  <DaysPicker
                    defaultSelected={[]}
                    preferredTime={form.pref_start_time_string}
                    onSelect={(e: any) => {
                      let tempForms = { ...form };
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      let [x, ..._hours] = e;
                      tempForms.hours = _hours;
                      setForm(tempForms);
                    }}
                    onPreferredTimeSelect={(e: any) => {
                      let tempForms = { ...form };
                      let a = new Date();
                      a.setHours(e.split(":")[0], e.split(":")[1]);
                      tempForms.pref_start_time = new Date(
                        a.getTime() - a.getTimezoneOffset() * 60000
                      ).toISOString();
                      tempForms.pref_start_time_string = moment(
                        e,
                        "HH:mm:ss"
                      ).format("hh:mm a");
                      setForm(tempForms);
                    }}
                    onPreferredFlexibleSelect={(e: any) => {
                      let tempForms = { ...form };
                      tempForms.is_flexible = e;
                      setForm(tempForms);
                    }}
                  />
                )}
                {step === 7 && (
                  <Form.Control
                    as={"textarea"}
                    type="text"
                    placeholder={"Additional Info"}
                    className="height-100px"
                    onChange={(e) =>
                      setForm({ ...form, addition_info: e.target.value })
                    }
                    value={form.addition_info}
                  ></Form.Control>
                )}
                {step === 8 && (
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center element-wrapper mb-3">
                      <Image style={{ width: "65px" }} src={BOTTOM_BOX} />
                      <div className="ms-2">
                        <p className="m-0 text-bold why-heading">
                          standard pricing
                        </p>
                        <p className="mt-1 why-description">
                          With our fair rates, no more wasting time getting
                          quotes and negotiating.(Save Time!)
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center element-wrapper mb-3">
                      <Image style={{ width: "65px" }} src={MIDDLE_BOX} />
                      <div className="ms-2">
                        <p className="m-0 text-bold why-heading">
                          Work with any Trainer
                        </p>
                        <p className="mt-1 why-description">
                          No commitment to one trainer, book any of our
                          qualified and vetted trainers at any time.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center element-wrapper mb-3">
                      <Image style={{ width: "65px" }} src={TOP_BOX} />
                      <div className="ms-2">
                        <p className="m-0 text-bold why-heading">
                          Secure and Simple
                        </p>
                        <p className="mt-1 why-description">
                          Easily make payments and track sessions all on our
                          app.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {step !== 3 &&
                  step !== 5 &&
                  stepDetails[step].data?.map((obj: any, index: number) => {
                    return (
                      <div
                        onClick={() => handleOptionSelect(step, obj, index)}
                        className={`optionContainer ${
                          obj.selected ? "optionSelected" : ""
                        }`}
                      >
                        {obj.icon && obj.name.toLowerCase() !== "other" ? (
                          <img
                            src={obj.icon}
                            alt=""
                            width={50}
                            color="#969696"
                          />
                        ) : (
                          <></>
                        )}
                        {obj.name &&
                        String(obj.name).toLowerCase() === "other" &&
                        (step === 0 || step === 1) ? (
                          <input
                            onChange={(e: any) => {
                              if (step === 0) {
                                setForm({
                                  ...form,
                                  other_category: e.target.value,
                                });
                              }
                              if (step === 1) {
                                setForm({
                                  ...form,
                                  other_goal: e.target.value,
                                });
                              }
                            }}
                            placeholder="other"
                            className="input-border"
                            value={
                              step === 0 ? form.other_category : form.other_goal
                            }
                          />
                        ) : (
                          <span>{obj.name}</span>
                        )}
                        {String(obj.name).toLowerCase() === "other" &&
                        (step === 0 || step === 1) ? null : (
                          <Image
                            className={
                              obj.selected
                                ? "option-tick-selected"
                                : "option-tick"
                            }
                            src={
                              obj.selected
                                ? step === 4 || step === 6
                                  ? IC_SELECT
                                  : IC_TICK
                                : IC_BOX
                            }
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </PrimaryModal>
      )}
    </>
  );
};

BroadcastRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.any,
  title: PropTypes.any,
};

export { BroadcastRequestModal };
