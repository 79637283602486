export const stepsArray: any = [
  {
    title: "SELECT YOUR CATEGORIES",
    sub_title: "Select all that apply",
    type: "MCQ",
    back: false,
    progress: 10,
    data: [],
  },
  {
    title: "WHAT ARE YOUR GOALS?",
    sub_title: "Select all that apply",
    back: false,
    type: "MCQ",
    progress: 20,
    data: [],
  },
  {
    title: "DO YOU HAVE ANY INJURIES OR PRE-EXISTING MEDICAL CONDITIONS?",
    sub_title: "Select all that apply",
    progress: 30,
    type: "MCQ",
    back: true,
    data: [],
  },
  {
    title: "WHERE WOULD YOU LIKE TO TRAIN",
    sub_title: "",
    data: [
      {
        id: "home",
        name: "Home",
      },
      {
        id: "apartment",
        name: "Apartment Gym",
      },
      {
        id: "office",
        name: "Office",
      },
      {
        id: "outdoor",
        name: "Outdoor",
      },
      {
        id: "online",
        name: "Online",
      },
      {
        id: "recommended",
        name: "As recommended by trainer",
      },
      {
        id: "other",
        name: "Other",
      },
    ],
    progress: 40,
    back: true,
  },
  {
    title: "HOW OFTEN WOULD YOU LIKE TO TRAIN?",
    sub_title: "Select an option",
    data: [
      {
        id: "Daily",
        name: "Daily",
      },
      {
        id: "Several times a week",
        name: "Several times a week",
      },
      {
        id: "Once a week",
        name: "Once a week",
      },
      {
        id: "As recommended by professional",
        name: "As recommended by professional",
      },
      {
        id: "Other",
        name: "Other",
      },
    ],
    progress: 50,
    type: "SCQ",
    back: true,
  },
  {
    title: "WHEN ARE YOU AVAILABLE FOR TRAINING?",
    sub_title: "Select all that apply",
    back: true,
    progress: 65,
  },
  {
    title: "DO YOU PREFER TO WORK WITH A TRAINER OF A CERTAIN GENDER?",
    sub_title: "Select one option",
    data: [
      {
        id: "female",
        name: "Female",
      },
      {
        id: "male",
        name: "Male",
      },
      {
        id: "none",
        name: "No Preference",
      },
    ],
    progress: 80,
    type: "SCQ",
    back: true,
  },
  {
    title: "ALMOST DONE!",
    sub_title: "Any other information that your trainer should know about you",
    progress: 100,
    finish: false,
    back: false,
  },
  {
    title:
      "You will receive profiles of available trainers soon or you can start searching all profiles.",
    sub_title: "WHY CHOOSE SEND ME A TRAINER?",
    finish: true,
    back: false,
    isLoading: true,
  },
];
