import {
  postCreateRequestOpportunityApi,
  postCreateQuickRequestOpportunityApi,
  getOpenFormsApi,
  getCategoriesApi,
  getGoalsApi,
  getInjuriesApi,
  getBroadcastStatesApi,
  getBroadcastCitiesApi,
  postCreateBroadcastRequestApi,
} from "../apis";
import { AppDispatch } from "../store";
import {
  SET_BROADCAST_REQUEST_MODAL_IS_VISIVLE,
  SET_CATEGORIES,
  SET_CITIES_FOR_BROADCAST,
  SET_GOALS,
  SET_INJURIES,
  SET_STATES_FOR_BROADCAST,
} from "../types";

const setBroadcastRequestIsVisibleAction = (payload: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_BROADCAST_REQUEST_MODAL_IS_VISIVLE, payload });
  };
};

const postCreateRequestOpportunityAction = (
  payload: any,
  successCallback?: any,
  errorCallback?: any
) => {
  return async (dispatch: AppDispatch) => {
    let response: any = await postCreateRequestOpportunityApi(
      payload,
      (res) => {
        const { result } = res?.data;
        if (successCallback) {
          successCallback(result);
        }
      },
      (err) => {
        if (errorCallback) {
          errorCallback(err);
        }
        console.error(err);
      }
    );
    const { result, status } = response?.data;
    if (status === 200) {
      return result;
    } else {
      return response;
    }
  };
};

const postCreateQuickRequestOpportunityAction = (
  payload: any,
  successCallback?: any,
  errorCallback?: any
) => {
  return async (dispatch: AppDispatch) => {
    let response: any = await postCreateQuickRequestOpportunityApi(
      payload,
      (res) => {
        const { result } = res?.data;
        if (successCallback) {
          successCallback(result);
        }
      },
      (err) => {
        if (errorCallback) {
          errorCallback(err);
        }
        console.error(err);
      }
    );
    const { result, status } = response?.data;
    if (status === 200) {
      return result;
    } else {
      return response;
    }
  };
};

const getOpenFormsAction = (category_id: number) => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getOpenFormsApi(category_id);
      if (response.status === 200) {
        return response.result;
      }
      return response;
    } catch (err) {
      return false;
    }
  };
};

const getCategoriesAction = (franchise_id: number) => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getCategoriesApi(franchise_id);
      if (response.status === 200) {
        dispatch({ type: SET_CATEGORIES, payload: response.result });
      }
    } catch (error) {
      return false;
    }
  };
};

const getGoalsAction = () => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getGoalsApi();
      if (response.status === 200) {
        dispatch({ type: SET_GOALS, payload: response.result });
      }
    } catch (error) {
      return false;
    }
  };
};

const getInjuriesAction = () => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getInjuriesApi();
      if (response.status === 200) {
        dispatch({ type: SET_INJURIES, payload: response.result });
      }
    } catch (error) {
      return false;
    }
  };
};

const getBroadcastStatesAction = (country_id: number, all: boolean) => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getBroadcastStatesApi(country_id, all);
      if (response.status === 200) {
        dispatch({ type: SET_STATES_FOR_BROADCAST, payload: response.result });
      }
    } catch (error) {
      return false;
    }
  };
};

const getBroadcastCitiesAction = (
  country_id: number,
  state_id: number,
  all: boolean
) => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const response = await getBroadcastCitiesApi(country_id, state_id, all);
      if (response.status === 200) {
        dispatch({ type: SET_CITIES_FOR_BROADCAST, payload: response.result });
      }
    } catch (error) {
      return false;
    }
  };
};

const postCreateBroadcastRequestAction = (
  payload: any,
  successCallback?: any,
  errorCallback?: any
) => {
  return async (dispatch: AppDispatch) => {
    let response: any = await postCreateBroadcastRequestApi(
      payload,
      (res) => {
        const { result } = res?.data;
        if (successCallback) {
          successCallback(result);
        }
      },
      (err) => {
        if (errorCallback) {
          errorCallback(err);
        }
        console.error(err);
      }
    );
    const { result, status } = response?.data;
    if (status === 200) {
      return result;
    } else {
      return response;
    }
  };
};

export {
  setBroadcastRequestIsVisibleAction,
  postCreateRequestOpportunityAction,
  postCreateQuickRequestOpportunityAction,
  getOpenFormsAction,
  getCategoriesAction,
  getGoalsAction,
  getInjuriesAction,
  getBroadcastStatesAction,
  getBroadcastCitiesAction,
  postCreateBroadcastRequestAction,
};
