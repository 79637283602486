import {
  SET_BROADCAST_REQUEST_MODAL_IS_VISIVLE,
  SET_CATEGORIES,
  SET_CITIES_FOR_BROADCAST,
  SET_GOALS,
  SET_INJURIES,
  SET_STATES_FOR_BROADCAST,
} from "../types";

const INITIAL_STATE = {
  isBroadcastRequestModalVisible: false,
  categories: [],
  goals: [],
};

const broadcastRequestReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_BROADCAST_REQUEST_MODAL_IS_VISIVLE:
      return {
        ...state,
        isBroadcastRequestModalVisible: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_GOALS:
      return {
        ...state,
        goals: action.payload,
      };
    case SET_INJURIES:
      return {
        ...state,
        injuries: action.payload,
      };
    case SET_STATES_FOR_BROADCAST:
      return {
        ...state,
        states: action.payload,
      };
    case SET_CITIES_FOR_BROADCAST:
      return {
        ...state,
        cities: action.payload,
      };
    default:
      return state;
  }
};

export { broadcastRequestReducer };
